import styled from '@style'

import { Text as BaseText } from '@components/Text'

const Text = styled(BaseText)`
  max-width: 500px;
`

Text.displayName = `Hero.Text`

Text.defaultProps = {
  pl: [0, 6],
}

export { Text }
