/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Formik, Form } from 'formik'
import jsonp from 'jsonp'
import qs from 'qs'
import { Machine } from 'xstate'
import { useMachine } from '@xstate/react'

import { Box } from '@components/Grid'
import { H2, H4 } from '@components/Heading'
import { Text } from '@components/Text'
import { TextInput } from '@components/Form'
import { Button } from '@components/Button'
import { OutboundLink } from '@components/Link'

import { Container } from './Container'
import { Blob1, Blob2 } from './Blobs'

const subscribeMachine = Machine({
  id: 'subscribe',
  initial: 'idle',
  states: {
    idle: {
      on: { SUBMIT: 'loading' },
    },
    loading: {
      on: {
        SUCCESS: 'success',
        ERROR: 'error',
        ALREADY_SUBSCRIBED: 'already_subscribed',
      },
    },
    success: {
      on: { CLOSE: 'idle' },
    },
    error: {
      on: { CLOSE: 'idle' },
    },
    already_subscribed: {
      on: {
        CLOSE: 'idle',
      },
    },
  },
})

const PureNewsletter = ({
  content: {
    heading,
    text,
    placeholder,
    buttonLabel,
    buttonLabelLoading,
    errorHeading,
    errorText,
    successText,
    successHeading,
    alreadySubscribedText,
    alreadySubscribedLink,
    alreadySubscribedHeading,
  },
}) => {
  const [preferenceLink, setPreferenceLink] = useState(null)
  const [state, send] = useMachine(subscribeMachine)

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
        // Return if email is empty
        if (!values.email) {
          setSubmitting(false)
          setFieldError('email', 'Please enter an email address')
          return
        }
        send('SUBMIT')
        setSubmitting(true)

        const emailEncoded = qs.stringify({ EMAIL: values.email })
        const audienceID = `463293a97f`
        const mailChimpUrl = `https://notebaert-consulting.us13.list-manage.com/subscribe/post-json`
        const queryParams = `?u=d3f37ec0fd3d8c4a15d54a698&id=${audienceID}&${emailEncoded}`
        const endpoint = `${mailChimpUrl}${queryParams}`

        jsonp(endpoint, { param: 'c', timeout: 3500 }, (error, response) => {
          // An error occured when calling the Mailchimp API
          if (error) {
            send('ERROR')
          }
          if (response) {
            if (response.result === 'success') {
              // Subscription to list was successful
              send('SUCCESS')
            }
            // Mailchimp API returned a specific error
            if (response.result === 'error') {
              if (
                response.msg.endsWith('Click here to update your profile</a>')
              ) {
                // Remove all backblashes
                const sanitizedMessage = response.msg.replace(/\\/g, '')
                // Find href
                const regex = /<a[^>]* href="([^"]*)"/
                const link = regex.exec(sanitizedMessage)[1]
                setPreferenceLink(link)
                send('ALREADY_SUBSCRIBED')
              } else {
                send('ERROR')
              }
            } else {
              send('ERROR')
            }
          }
          resetForm()
        })
      }}
    >
      {() => (
        <Container>
          <Box
            width="100%"
            maxWidth="900px"
            mx="auto"
            p={[6, 6, 7]}
            display="grid"
            gridTemplateColumns={['4fr 1fr', '1fr 3fr 1fr']}
            gridTemplateAreas={[
              "'heading blob1' 'body body' 'form form'",
              "'... heading blob1' '... body ...' 'blob2 form ...'",
            ]}
          >
            <Blob1 gridArea="blob1" />
            <Blob2 gridArea="blob2" />
            <Box gridArea="heading">
              <H2 mb={[5, 5, 6]} textAlign={['left', 'left', 'center']}>
                {heading}
              </H2>
            </Box>
            <Box gridArea="body">
              <Text color="grey.700" mb={[5, 5, 6]}>
                {text}
              </Text>
            </Box>
            <Box gridArea="form">
              <Form>
                <Box
                  display="grid"
                  gridTemplateColumns={['100%', null, '1fr fit-content(300px)']}
                  gridTemplateAreas={[
                    "'input' 'button'",
                    null,
                    "'input button'",
                  ]}
                >
                  <Box gridArea="input" mr={[0, null, 4]}>
                    <TextInput
                      name="email"
                      type="email"
                      placeholder={placeholder}
                      data-testid="email"
                      mb={[4]}
                    />
                  </Box>
                  <Box gridArea="button">
                    <Button type="submit" fullWidth>
                      {state.matches('loading')
                        ? buttonLabelLoading
                        : buttonLabel}
                    </Button>
                  </Box>
                </Box>
              </Form>
            </Box>
          </Box>

          {state.matches('error') ? (
            <Box
              position="absolute"
              top="0"
              bottom="0"
              left="0"
              right="0"
              bg="red.light"
              display="grid"
              css={{ placeContent: 'center' }}
              p={6}
            >
              <Box maxWidth="400px">
                <H4 color="white" mb={4}>
                  {errorHeading}
                </H4>
                <Text color="white" mb={5}>
                  {errorText}
                </Text>
                <Button variant="secondary" onClick={() => send('CLOSE')}>
                  Close
                </Button>
              </Box>
            </Box>
          ) : null}

          {state.matches('success') ? (
            <Box
              position="absolute"
              top="0"
              bottom="0"
              left="0"
              right="0"
              bg="green.700"
              display="grid"
              css={{ placeContent: 'center' }}
              p={6}
            >
              <Box maxWidth="400px">
                <H4 color="white" mb={4}>
                  {successHeading}
                </H4>
                <Text color="white" mb={5}>
                  {successText}
                </Text>
                <Button variant="secondary" onClick={() => send('CLOSE')}>
                  Close
                </Button>
              </Box>
            </Box>
          ) : null}

          {state.matches('already_subscribed') ? (
            <Box
              position="absolute"
              top="0"
              bottom="0"
              left="0"
              right="0"
              bg="green.700"
              display="grid"
              css={{ placeContent: 'center' }}
              p={6}
            >
              <Box maxWidth="400px">
                <H4 color="white" mb={4}>
                  {alreadySubscribedHeading}
                </H4>
                <Text color="white" mb={5}>
                  {alreadySubscribedText}{' '}
                  <OutboundLink
                    css={{
                      color: 'white !important',
                      textDecoration: 'underline !important',
                      background: 'none !important',
                    }}
                    to={preferenceLink ?? '#'}
                  >
                    {alreadySubscribedLink}
                  </OutboundLink>
                </Text>
                <Button variant="secondary" onClick={() => send('CLOSE')}>
                  Close
                </Button>
              </Box>
            </Box>
          ) : null}
        </Container>
      )}
    </Formik>
  )
}

PureNewsletter.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    buttonLabelLoading: PropTypes.string.isRequired,
    successHeading: PropTypes.string.isRequired,
    successText: PropTypes.string.isRequired,
    errorHeading: PropTypes.string.isRequired,
    errorText: PropTypes.string.isRequired,
    alreadySubscribedHeading: PropTypes.string.isRequired,
    alreadySubscribedText: PropTypes.string.isRequired,
    alreadySubscribedLink: PropTypes.string.isRequired,
  }).isRequired,
}

const NewsletterSection = () => {
  const { content } = useStaticQuery(
    graphql`
      query NewsletterQuery {
        content: sanityNewsletterSingleton {
          heading
          text
          placeholder
          buttonLabel
          buttonLabelLoading
          successHeading
          successText
          errorHeading
          errorText
          alreadySubscribedHeading
          alreadySubscribedText
          alreadySubscribedLink
        }
      }
    `
  )
  return <PureNewsletter content={content} />
}

NewsletterSection.displayName = `NewsletterSection`

export { NewsletterSection, PureNewsletter }
