import styled, { themeGet } from '@style'

const Container = styled.div`
  z-index: 1;
  position: relative;
  overflow: hidden;
  background: ${themeGet('colors.newsletterSection.background')};
`

export { Container }
