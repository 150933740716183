/* eslint-disable react/prop-types */
import React from 'react'
import { Box } from '@components/Grid'
import styled, {
  background as backgroundStyles,
  layout,
  position,
} from '@style'

const Circle = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border-radius: 50%;
  transform: translate3d(-50%, 0, 0);
  /* stylelint-disable */
  box-shadow: 0 0.3px 1.3px rgba(0, 0, 0, 0.034),
    0 0.8px 3.3px rgba(0, 0, 0, 0.049), 0 1.6px 6.7px rgba(0, 0, 0, 0.061),
    0 3.3px 13.9px rgba(0, 0, 0, 0.076), 0 9px 38px rgba(0, 0, 0, 0.11);
  /* stylelint-enable */
  ${backgroundStyles}
  ${layout}
  ${position}
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    /* stylelint-disable */
    box-shadow: 0 0.8px 1.6px rgba(0, 0, 0, 0.043),
      0 1.9px 4.2px rgba(0, 0, 0, 0.062), 0 3.9px 8.5px rgba(0, 0, 0, 0.078),
      0 8px 17.5px rgba(0, 0, 0, 0.097), 0 22px 48px rgba(0, 0, 0, 0.14);
    /* stylelint-enable */
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    transform: translate3d(-50%, -1px, 0);

    &::after {
      opacity: 1;
    }
  }
`

Circle.defaultProps = {
  zIndex: '20',
  position: 'absolute',
  bottom: '0',
  left: '50%',
  size: '48px',
  gradientAngle: '180deg',
  background: 'papayawhip',
}

const ArrowSVG = props => (
  <svg viewBox="0 0 16 25" fill="none" {...props}>
    <path
      d="M7.29289 24.2271C7.68342 24.6176 8.31658 24.6176 8.70711 24.2271L15.0711 17.8631C15.4616 17.4726 15.4616 16.8394 15.0711 16.4489C14.6805 16.0584 14.0474 16.0584 13.6569 16.4489L8 22.1058L2.34315 16.4489C1.95262 16.0584 1.31946 16.0584 0.928931 16.4489C0.538407 16.8394 0.538407 17.4726 0.928931 17.8631L7.29289 24.2271ZM7 0.47998L7 23.52L9 23.52L9 0.479981L7 0.47998Z"
      fill="inherit"
    />
  </svg>
)

const Arrow = styled(ArrowSVG)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  height: 25px;
  fill: currentColor;
`

const ScrollDown = ({ color, background, ...props }) => (
  <Circle background={background} {...props}>
    <Box color={color} position="relative" height="100%" width="100%" bg="red">
      <Arrow />
    </Box>
  </Circle>
)

export { ScrollDown }
