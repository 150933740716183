import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

import { Input } from './Input'

const TextInput = ({ name, type, ...props }) => {
  const [field] = useField({ name, type })

  return <Input id={name} {...field} {...props} />
}

TextInput.defaultProps = {
  type: 'text',
}

TextInput.propTypes = {
  /** Name specifies for which value within a form this input is */
  name: PropTypes.string.isRequired,
  /** Type of input */
  type: PropTypes.string,
}

export { TextInput }
