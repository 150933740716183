import React from 'react'
import PropTypes from 'prop-types'
import styled from '@style'

const shapes = {
  default: {
    path:
      'M0 1.11182V263.811H1400V0C1279.65 76.4531 1121.16 129.451 972.709 168.082C722.092 233.595 500.065 258.224 284.602 178.404C181.127 139.902 79.0938 77.1443 0 1.11182Z',
    height: '264',
  },
  wide: {
    path:
      'M0 0L0 145.723H1400V10.6179C1222.2 108.116 951.522 115.31 697.069 113.395C431.171 111.915 183.633 100.313 0 0Z',
    height: '146',
  },
  homePage: {
    path:
      'M0 48.135C224.595 180.481 546.866 183.095 858.737 141.476C1051.03 115.599 1239.13 72.7749 1400 0.263428V193H0V48.135Z',
    height: '193',
  },
}

const BaseSVG = ({ shape, ...props }) => (
  <svg
    viewBox={`0 0 1400 ${shapes[shape].height}`}
    fill="none"
    {...props}
    preserveAspectRatio="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={shapes[shape].path}
      fill="inherit"
    />
  </svg>
)

BaseSVG.defaultProps = {
  shape: 'default',
}

BaseSVG.propTypes = {
  shape: PropTypes.oneOf(['default', 'wide', 'homePage']),
}

const SVG = styled(BaseSVG)`
  position: absolute;
  width: 100%;
  bottom: -2px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  fill: ${props => props.color};
`

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`

const Shape = props => (
  <Container>
    <SVG {...props} />
  </Container>
)

export { Shape }
