import { css, themeGet } from '@style'

const commonStyles = css`
  border-radius: ${themeGet('radii.1')};
  min-height: 50px;
  width: 100%;
  padding-left: ${themeGet('space.4')};
  padding-right: ${themeGet('space.4')};
  font-size: ${themeGet('fontSizes.1')};
  border: none;
  backface-visibility: hidden;
  outline: 0;
  /* stylelint-disable */
  color: ${themeGet(`colors.form.common.default.inputTextColor`)};
  background: ${themeGet(`colors.form.common.default.inputBackgroundColor`)};
  box-shadow: inset 0 0 0 1px
    ${themeGet(`colors.form.common.default.inputBorderColor`)};

  &::placeholder {
    color: ${themeGet(`colors.form.common.default.inputPlaceholderColor`)};
  }

  &:focus {
    background: ${themeGet(`colors.form.common.focused.inputBackgroundColor`)};
    box-shadow: inset 0 0 0 2px
      ${themeGet(`colors.form.common.focused.inputBorderColor`)};
  }
  /* stylelint-enable */
`

export { commonStyles }
