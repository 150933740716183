import React from 'react'
import PropTypes from 'prop-types'

import styled, { space, color, layout, propTypes } from '@style'
import showReelwebm from './assets/showReel.webm'

const Section = styled.section`
  position: relative;
  background: black;
  ${space}
  ${color}
  ${layout}
`

const Gradient = styled.div`
  position: absolute;
  background: ${props => props.gradient};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Video = props => (
  <StyledVideo autoPlay muted loop {...props}>
    <source src={showReelwebm} type="video/webm" />
  </StyledVideo>
)

const VideoBackground = ({ children, gradient, ...props }) => (
  <Section {...props}>
    <Video />
    <Gradient gradient={gradient} />
    {children}
  </Section>
)

VideoBackground.defaultProps = {
  gradient: 'linear-gradient(0deg, hsla(0, 0%, 0%, 0.8), hsla(0, 0%, 0%, 0.7))',
}

VideoBackground.displayName = `Hero.VideoBackground`

VideoBackground.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  gradient: PropTypes.string,
}

export { VideoBackground }
