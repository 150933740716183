import styled, { space, layout, position, grid, propTypes } from '@style'
import { commonStyles } from '../commonStyles'

const Input = styled.input`
  ${commonStyles}
  ${space}
  ${layout}
  ${position}
  ${grid}
`

Input.displayName = `Input`

Input.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.position,
  ...propTypes.grid,
}

export { Input }
