import { Button as BaseButton } from '@components/Button'
import styled, { themeGet } from '@style'

const Button = styled(BaseButton)`
  margin-top: ${themeGet('space.6')};

  @media screen and (min-width: ${themeGet('breakpoints.0')}) {
    margin-left: ${themeGet('space.6')};
  }
`

export { Button }
