import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'

import styled, { space, color, layout, propTypes } from '@style'

const Section = styled.section`
  position: relative;
  ${space}
  ${color}
  ${layout}
`

const Gradient = styled.div`
  position: absolute;
  background: ${props => props.gradient};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Background = ({ children, image, gradient, ...props }) => (
  <Section {...props}>
    {image && (
      <Img
        fluid={image}
        objectFit="cover"
        objectPosition="50% 50%"
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
      />
    )}
    <Gradient gradient={gradient} />
    {children}
  </Section>
)

Background.defaultProps = {
  gradient: 'linear-gradient(0deg, hsla(0, 0%, 0%, 0.8), hsla(0, 0%, 0%, 0.8))',
}

Background.displayName = `Hero.Background`

Background.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  gradient: PropTypes.string,
}

export { Background }
