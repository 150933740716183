import { Box } from '@components/Grid'
import styled, { themeGet } from '@style'

const Blob1 = styled(Box)`
  /* stylelint-disable */
  background: linear-gradient(
    210deg,
    ${themeGet('colors.red.base')},
    ${themeGet('colors.red.dark')}
  );
  /* stylelint-enable */
  width: 200%;
  padding-bottom: 200%;
  position: absolute;
  bottom: 25%;
  border-radius: 30% 70% 54% 46% / 60% 23% 77% 40%;
`

const Blob2 = styled(Box)`
  position: absolute;
  /* stylelint-disable */
  background: linear-gradient(
    210deg,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.7)
  );
  /* stylelint-enable */
  width: 70%;
  padding-bottom: 70%;
  top: 50%;
  border-radius: 30% 70% 54% 46% / 60% 23% 77% 40%;

  @media screen and (min-width: ${themeGet('breakpoints.sm')}) {
    width: 300%;
    padding-bottom: 300%;
    left: -30%;
    top: -15%;
  }
`

export { Blob1, Blob2 }
