import { H1, Topline } from '@components/Heading'
import styled, { themeGet, space, color, layout, propTypes } from '@style'

import { Text } from './Text'
import { Button } from './Button'
import { Background } from './Background'
import { VideoBackground } from './VideoBackground'
import { Shape } from './Shape'
import { ScrollDown } from './ScrollDown'

const Hero = styled.div`
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: -3px;
  position: relative;

  h1 {
    margin-bottom: ${themeGet('space.6')};
  }

  ${space}
  ${color}
  ${layout}
`

Hero.displayName = `Hero`

Hero.Heading = H1
Hero.Topline = Topline
Hero.Text = Text
Hero.Button = Button
Hero.Background = Background
Hero.VideoBackground = VideoBackground
Hero.Shape = Shape
Hero.ScrollDown = ScrollDown

Hero.defaultProps = {
  pt: [5, 6, 7],
  pb: [5, 6, 7],
  pl: [5, 6, 7],
  pr: [5, 6, 7],
}

Hero.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

export { Hero }
